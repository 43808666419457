<template>
  <div class="row mt-4 mx-1 justify-content-center">
    <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
      <div class="background-white">
        <div class="title">
          <div class="position">Installateur</div>
        </div>
      </div>
      <form class="my-4" @submit.prevent="addPRE">
        <div class="center">
          <b-form-group
            label="JE FAIS MOI MÊME LES TRAVAUX ?"
            v-slot="{ ariaDescribedby }"
            class="form-input custom-placeholder legend mt-3"
          >
            <div
              class="d-flex align-items-center form-input custom-placeholder custom-input"
            >
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                class="mb-3"
                name="some-radios"
                value="A"
              >
                OUI
              </b-form-radio>
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                class="mb-3"
                name="some-radios"
                value="B"
              >
                NON
              </b-form-radio>
            </div>
          </b-form-group>

          <div>
            <b-form-group class="mt-4" v-if="selected === 'B'">
              <multiselect
              v-model="selectedSousTraitantData"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getAllsousTraitant"
              label="name"
              track-by="id"
              :placeholder="$t('Select_agent')"
              class="form-input custom-placeholder custom-input"
            />
              <!-- <searchInput
                :list="getAllsousTraitant"
                :loader="getLoadingsousTraitant"
                label="name"
                placeholder="sélectionne sous-traitant"
                :searchFunc="allsousTraitant"
                @searchfilter="selectSousTraitant($event)"
                :filtre="{ entreprise: form.id }"
                :with_default="0"
                class="form-input custom-placeholder custom-input"
              >
              </searchInput> -->
            </b-form-group>

            <b-form-group
              :label="$t('NAME')"
              label-for="name"
              v-if="selected === 'B'"
            >
              <b-form-input
                id="name"
                :placeholder="$t('NAME')"
                class="form-input custom-placeholder custom-input"
                v-model="selectedSousTraitant.name"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="name-feedback"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('SIRET')"
              label-for="num_siret"
              v-if="selected === 'B'"
            >
              <b-form-input
                id="num_siret"
                class="form-input custom-placeholder custom-input"
                v-model="selectedSousTraitant.num_siret"
                :placeholder="$t('SIRET')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="num_siret-feedback"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('RGE')"
              label-for="num_rge"
              class="mb-4 index"
            >
          
           
            <multiselect
            v-model="selectedCertificat"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllCertificatRges"
            label="numero"
            track-by="id"
            placeholder="sélectionne rge"
            class="form-input custom-placeholder custom-input"
          /> 
            </b-form-group>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import searchInput from "../../ui/searchInput.vue";

export default {
  
  data() {
    return {
      certificat_rge: this.devisObject.data.devis.certificat_rge,
      selectedSousTraitant: this.initializeSousTraitant(),
      sousTraitantAdd:
        this.devisObject?.data?.groupeLigneDocument[0]?.sous_traitant === null,
      save: 0,
      selectedvalue: false,
      erreurlist: {
        name: null,
        num_siret: null,
      },
      selected: this.devisObject?.data?.groupeLigneDocument[0]?.sous_traitant
        ? "B"
        : "A",
      errorS: false,
      box: "",
      form3: {
        name: null,
        num_siret: null,
        num_rge: null,
        certificat_rge: [],
      },
      form: {},
    };
  },
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "getLoadingCertificatRge",
      "getOnlineUser",
      "getAllsousTraitant",
      "getLoadingsousTraitant",
      "getAllCertificatRges"
    ]),
    selectedCertificat: {
      get() {
        if (typeof this.certificat_rge === 'object' && this.certificat_rge !== null) {
          return this.certificat_rge;
        } else {
          return this.getAllCertificatRges.find(
            option => option.id === this.certificat_rge
          );
        }
      },
      set(value) {
       
        this.certificat_rge = value ? value.id : null;
        this.devisObject.data.devis.certificat_rge_id = value.id;
        this.emitUpdate()
      },
    },
  //   selectedSousTraitantData: {
  //     get() {
  //       // Si `selectedSousTraitant` est déjà un objet, on le retourne
  //       if (
  //         typeof this.selectedSousTraitant === "object" &&
  //         this.selectedSousTraitant !== null
  //       ) {
    
  //         return this.selectedSousTraitant;
          
  //       } else {
  //         // Sinon, on recherche le client dans la liste des clients
  //         return this.getAllsousTraitant.find(
  //           (option) => option.id === this.selectedSousTraitant
  //         );
  //       }
        
  //     },
  //     set(value) {
  //       if (value && Object.keys(value).length > 0) {
  //         this.selectedSousTraitant = { ...value };
  //         this.devisObject.data.groupeLigneDocument[0].sous_traitant = {
  //           ...value,
  //         };
  //         this.devisObject.data.groupeLigneDocument[0].sous_traitant_id =
  //           value.id;

  //         this.selectedvalue = true;
         
  //         this.emitUpdate();
  //       } else {
  //         this.selectedvalue = false;
  //       }

  //       // this.selectedSousTraitant = value
  //       // this.selected = true;
       
  //     },
  //   },
  // },
  selectedSousTraitantData: {
    get() {
      // Return selectedSousTraitant if it's already an object
      if (
        typeof this.selectedSousTraitant === "object" &&
        this.selectedSousTraitant !== null
      ) {
        console.log("selectedSousTraitant",this.selectedSousTraitant)
        return this.selectedSousTraitant;
      } else {
        // Otherwise, search for the sous-traitant by id
        return this.getAllsousTraitant.find(
          (option) => option.id === this.selectedSousTraitant
        );
      }
    },
    set(value) {
         if (value && Object.keys(value).length > 0) {
           this.selectedSousTraitant = { ...value };
           this.devisObject.data.groupeLigneDocument[0].sous_traitant = {
             ...value,
           };
           this.devisObject.data.groupeLigneDocument[0].sous_traitant_id =
             value.id;

           this.selectedvalue = true;
         
           this.emitUpdate();
         } else {
           this.selectedvalue = false;
        }

         // this.selectedSousTraitant = value
         // this.selected = true;
       
      },
     },
      },
  watch: {
    selectedSousTraitantData(newVal) {
    // Watch for changes in selectedSousTraitantData and update selectedvalue
    this.selectedvalue = !!newVal;
  },

    selected: {
      immediate: true,
      handler() {

        this.fetchData();
      },
    },
  },
  created() {
    this.allsousTraitant({
      search: "",
    });
    
  },
  methods: {
    ...mapActions([
      "store_sousTraitant",
      "storeCertificatRge",
      "all_entreprises",
      "allsousTraitant",
      "allCertificatRge",
      "sousTraitant",
      "edit_sousTraitant",
      "update_devis_group_BAR",
    ]),
    initializeSousTraitant() {
      const sousTraitant =
        this.devisObject?.data?.groupeLigneDocument[0]?.sous_traitant || {};
      return {
        id:sousTraitant.id,
        name: sousTraitant.name || "",
        num_siret: sousTraitant.num_siret || "",
      };
    },

    selectCat(certificats) {
      this.certificat_rge = { ...certificats };
      this.devisObject.data.devis.certificat_rge_id = certificats.id;
     
      this.emitUpdate();
    },
    validateInputs() {
      return true;
    },
    async fetchData() {
      if (this.selected === "A") {
        await this.allCertificatRge({
          page: 1,
          per_page: 10,
          search: "",
          sous_traitant_id: "",
          entreprise_id: this.getOnlineUser.entreprise.id,
        });

        this.emitUpdate();
      } else if (this.selected === "B") {
        if (this.selectedvalue) {
          await this.allCertificatRge({
            page: 1,
            per_page: 10,
            search: "",
            sous_traitant_id: "",
            entreprise_id: this.getOnlineUser.entreprise.id,
          });
        } else {
          await this.allCertificatRge({
            page: 1,
            per_page: 10,
            search: "",
            sous_traitant_id: this.selectedSousTraitant.id,
            entreprise_id: "",
          });
        }
      }
    },
    async edit() {
  if (this.selected === "A") {
    const result = [
      {
        id: this.devisObject.data.groupeLigneDocument[0].id,
        sous_traitant_id: null,
      },
    ];
    await this.update_devis_group_BAR({ result });
    this.emitUpdate();
  } 
  else if (this.selected === "B") {
    console.log('this.devisObject.data.groupeLigneDocument[0].sous_traitant',this.devisObject.data.groupeLigneDocument[0].sous_traitant)
    console.log('this.selectedvalue',this.selectedvalue)
    if (this.selectedSousTraitant.id !== null) {

      if (this.selectedSousTraitant.id !== null) {
        const soustrait = {
          ...this.selectedSousTraitant,
          id: this.devisObject.data.groupeLigneDocument[0].sous_traitant.id,
        };
        await this.edit_sousTraitant(soustrait); 
        const result = [
          {
            id: this.devisObject.data.groupeLigneDocument[0].id,
            sous_traitant_id: this.selectedSousTraitant.id,
          },
        ];
        await this.update_devis_group_BAR({ result }); 
      }
    
      else {
        const data = {
          name: this.selectedSousTraitant.name,
          num_siret: this.selectedSousTraitant.num_siret,
          num_rge: "123654789",
        };
        const res = await this.store_sousTraitant(data); 
        this.devisObject.data.groupeLigneDocument[0].sous_traitant_id = res.id;
        const result = [
          {
            id: this.devisObject.data.groupeLigneDocument[0].id,
            sous_traitant_id: res.data.id,
          },
        ];
        await this.update_devis_group_BAR({ result }); 
      }
    } 
    else {
      
      const data = {
        name: this.selectedSousTraitant.name,
        num_siret: this.selectedSousTraitant.num_siret,
        num_rge: "123654789", 
      };
      const res = await this.store_sousTraitant(data); 
      const result = [
        {
          id: this.devisObject.data.groupeLigneDocument[0].id,
          sous_traitant_id: res.data.id, // Use the id of the newly stored subcontractor
        },
      ];
      await this.update_devis_group_BAR({ result }); 
    }

    this.emitUpdate();
  }
},
 
    selectSousTraitant(selected) {
      if (selected && Object.keys(selected).length > 0) {
        this.selectedSousTraitant = { ...selected };
        this.devisObject.data.groupeLigneDocument[0].sous_traitant = {
          ...selected,
        };
        this.devisObject.data.groupeLigneDocument[0].sous_traitant_id =
          selected.id;

        this.selectedvalue = true;
        this.emitUpdate();
      } else {
        this.selectedvalue = false;
      }
    },
    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
  },
};
</script>

<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
  margin-bottom: 25px;
  height: 60%;
}
.center .legend {
  font-size: 16px;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}
.custom-input {
  box-shadow: none;
}
.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.index {
  z-index: 20 !important;
}
</style>

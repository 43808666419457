<!-- <template>
  <div>
    <div class="row my-4 mx-1 justify-content-center">
      <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">Adresse de travaux</div>
          </div>
        </div>
        <form class="my-4">
          <div class="my-3">
            <b-form-checkbox
  v-model="devisObject.data.devis.same_travaux_address"
  @change="handleCheckboxChange"
  :label="$t('QUESTION_ADRESSE')"
  class="custom-input"
>
  Utiliser la même adresse du client pour les travaux.
</b-form-checkbox>
       
          </div>

      
          <div v-if="devisObject.data.devis.same_travaux_address">
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                :value="devisObject.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                :value="devisObject.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                :value="devisObject.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                v-model="devisObject.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                v-model="devisObject.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                v-model="devisObject.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originalTravauxAddress: {
        rue: this.devisObject.data.devis.rue_travaux,
        cp: this.devisObject.data.devis.cp_travaux,
        ville: this.devisObject.data.devis.ville_travaux,
      },
    };
  },
  
  methods: {
    handleCheckboxChange() {
      if (this.devisObject.data.devis.same_travaux_address) {
        // Remplir les champs de travaux avec les données du client
        this.devisObject.data.devis.rue_travaux = this.devisObject.data.client.rue;
        this.devisObject.data.devis.cp_travaux = this.devisObject.data.client.cp;
        this.devisObject.data.devis.ville_travaux = this.devisObject.data.client.ville;
      } else {
        // Restaurer les valeurs de travaux d'origine
        this.devisObject.data.devis.rue_travaux = this.originalTravauxAddress.rue;
        this.devisObject.data.devis.cp_travaux = this.originalTravauxAddress.cp;
        this.devisObject.data.devis.ville_travaux = this.originalTravauxAddress.ville;
      }
      this.emitUpdatedValues();
    },
    emitUpdatedValues() {
      this.$emit('update:devisObject', this.devisObject);
    },
  },
  watch: {
    devisObject: {
      handler(newVal) {
        console.log('devisObject updated in child:', newVal);
      },
      deep: true,
    },
  },
};
</script> -->
<template>
  <div>
    <div class="row my-4 mx-1 justify-content-center">
      <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">Adresse de travaux</div>
          </div>
        </div>
        <form class="my-4">
          <div class="my-3">
         
            <b-form-checkbox
              v-model="isSameTravauxAddress"
              @change="handleCheckboxChange"
              :label="$t('QUESTION_ADRESSE')"
              class="custom-input"
            >
              Utiliser la même adresse du client pour les travaux.
            </b-form-checkbox>
          </div>

          <!-- Affichage des données du client quand checkbox est cochée -->
          <div v-if="isSameTravauxAddress">
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                :value="devisObject.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                :value="devisObject.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                :value="devisObject.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                v-model="devisObject.data.devis.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                v-model="devisObject.data.devis.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                v-model="devisObject.data.devis.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                @input="emitUpdatedValues"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSameTravauxAddress: {
      get() {
        
        return this.devisObject.data.devis.same_travaux_address==1 ? true :false ;
      },
      set(value) {
        this.devisObject.data.devis.same_travaux_address = value;
        this.handleCheckboxChange();
      },
    },
  },
  data() {
    return {
      originalTravauxAddress: {
        rue: this.devisObject.data.devis.rue_travaux,
        cp: this.devisObject.data.devis.cp_travaux,
        ville: this.devisObject.data.devis.ville_travaux,
      },
    };
  },
  methods: {
    handleCheckboxChange() {
      if (this.isSameTravauxAddress) {
        // Fill in travaux fields with client data
        this.devisObject.data.devis.rue_travaux = this.devisObject.data.client.rue;
        this.devisObject.data.devis.cp_travaux = this.devisObject.data.client.cp;
        this.devisObject.data.devis.ville_travaux = this.devisObject.data.client.ville;
      } else {
        // Restore original travaux values
        this.devisObject.data.devis.rue_travaux = this.originalTravauxAddress.rue;
        this.devisObject.data.devis.cp_travaux = this.originalTravauxAddress.cp;
        this.devisObject.data.devis.ville_travaux = this.originalTravauxAddress.ville;
      }
      this.emitUpdatedValues();
    },
    emitUpdatedValues() {
      this.$emit('update:devisObject', this.devisObject);
    },
  },
  // watch: {
  //   devisObject: {
  //     handler(newVal) {
  //       // Update local data when devisObject changes
  //       this.originalTravauxAddress = {
  //         rue: newVal.data.devis.rue_travaux,
  //         cp: newVal.data.devis.cp_travaux,
  //         ville: newVal.data.devis.ville_travaux,
  //       };
  //       // Sync the checkbox state
  //       this.isSameTravauxAddress = newVal.data.devis.same_travaux_address;
  //     },
  //     deep: true,
  //   },
  // },
};
</script>


<style scoped>
.borderPointier {
  border: 2px dashed  #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px; 
}

.form-input {
  font-size: 13px; 
  height: 41.1px !important;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem; 
  left: 0; 
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-input {
  box-shadow: none; 
}
.title {
  color:#24316d;
  position: relative;
  z-index: 2;
  margin: 0; 
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem; 
}
  
</style>
<template>
  <div class="row my-4 mx-1 justify-content-center">
    <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
      <div class="background-white">
        <div class="title">
          <div class="position">Généralité</div>
        </div>
      </div>
      <form class="mt-4 mb-4" @submit.prevent="submitForm">
        <!-- Technical Visit Date Input -->
        <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
          <b-form-input
            type="date"
            v-model="devisObject.data.devis.visite_technique"
            class="form-input custom-placeholder custom-input"
            @input="emitUpdate"
            required
          ></b-form-input>
        </b-form-group>

        <!-- Start Date Input -->
        <b-form-group :label="$t('START')">
          <b-form-input
            type="date"
            v-model="devisObject.data.devis.debut_devis"
            required
            class="form-input custom-placeholder custom-input"
            @input="emitUpdate"
          ></b-form-input>
        </b-form-group>

        <!-- End Date Input -->
        <b-form-group :label="$t('END')">
          <b-form-input
            type="date"
            v-model="devisObject.data.devis.fin_devis"
            required
            class="form-input custom-placeholder custom-input"
            @input="emitUpdate"
          ></b-form-input>
        </b-form-group>
        <b-alert v-if="errorMessage" variant="danger" dismissible>
          {{ errorMessage }}
        </b-alert>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: null,
    };
  },
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.devisObject.data && this.devisObject.data.devis) {
      this.initializeDateData(); 
      this.formatDates(); 
    }
  },

  methods: {
  
    initializeDateData() {
      const devis = this.devisObject.data.devis;

  
      if (!devis.visite_technique) {
        devis.visite_technique = new Date().toISOString().slice(0, 10);
      }

    
      if (!devis.debut_devis) {
        devis.debut_devis = new Date().toISOString().slice(0, 10);
      }

  
      if (!devis.fin_devis) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30); 
        devis.fin_devis = futureDate.toISOString().slice(0, 10);
      }
    },

    formatDates() {
     // const addDay = 24 * 60 * 60 * 1000;

      if (this.devisObject.data.devis.visite_technique) {
        const visiteDate = new Date(this.devisObject.data.devis.visite_technique);
        visiteDate.setDate(visiteDate.getDate() );
        this.devisObject.data.devis.visite_technique = visiteDate.toISOString().slice(0, 10);
      }

      if (this.devisObject.data.devis.debut_devis) {
        const debutDate = new Date(this.devisObject.data.devis.debut_devis);
        debutDate.setDate(debutDate.getDate());
        this.devisObject.data.devis.debut_devis = debutDate.toISOString().slice(0, 10);
      }

      if (this.devisObject.data.devis.fin_devis) {
        const finDate = new Date(this.devisObject.data.devis.fin_devis);
        finDate.setDate(finDate.getDate());
        this.devisObject.data.devis.fin_devis = finDate.toISOString().slice(0, 10);
      }
    },
    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
  },
};
</script>


<style scoped>
.borderPointier {
  border: 2px dashed  #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px; 
  height: 41.1px !important;

}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem; 
  left: 0; 
  right: 0;
  z-index: 1;
  text-align: center;
}

.title {
  color:#24316d;
  position: relative;
  z-index: 2;
  margin: 0; 
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem; 
}
.custom-input {
  box-shadow: none; 
}


</style>




<template>
  <div>
    <div class="modal-test">
   


          <v-chip class="py-4 px-4 brouillon"
         
            v-if="devisObject.data.devis.brouillon === 0"
            style="background: #e4261b"
          >
            {{ $t("DRAFT") }}
          </v-chip>
          <v-chip  class="py-3 px-4 brouillon"
            
            v-if="devisObject.data.devis.brouillon === 1"
            style="background: #f8f8f8"
          >
            {{ $t("SIGNA") }}
          </v-chip>
         











      <div class="d-flex justify-content-between">

        <div class="d-flex">
         
          <div>
            <b>{{ $t("ESTIMATE") }} </b>
          </div>
          <span class="info">N° {{ devisObject.data.devis?.id }}</span>
        </div>
        <div class="d-flex">
          <span class="info d-none d-md-block">
            {{ onlineUser.entreprise.lib }}</span
          >
        </div>
      </div>
      <div class="part justify">
        <div class="divborder"></div>

        <div class="left">
          <div class="block">
            <div class="d-flex">
              <span class="title">{{ $t("Numéro client") }}: {{ devisObject.data?.devis?.client?.id }} </span>
              <span> {{ devisObject.data.devis?.client?.prenom }} </span>
              <span> {{ devisObject.data?.client?.nom }} </span>
            </div>
            <div class="d-flex">
              <span class="title">{{ $t("DATE_VISITE_TECHNIQUE") }}:</span>
              <span> {{ devisObject.data?.devis?.visite_technique }}</span>
            </div>
            <div class="d-flex">
              <span class="title">{{ $t("DATE_DEVIS") }}:</span>
              <span> {{ devisObject.data?.devis?.debut_devis }} </span>
            </div>
            <div class="d-flex">
              <span class="title">{{ $t("DATE-FIN") }}:</span>
              <span> {{ devisObject.data?.devis?.fin_devis }} </span>
            </div>
            <div class="d-flex">
              <span class="title">{{ $t("Adresse des travaux") }}:</span>
              <span>
                {{ devisObject.data?.devis?.rue_travaux }}
                {{ devisObject.data?.devis?.ville_travaux }}
                {{ devisObject.data?.devis?.cp_travaux }}
              </span>
            </div>
          <div>parcelle cadastrale:    {{ devisObject.data.devis?.logement?.parcelle_cadastrale }}</div>
          </div>
        </div>

        <div class="right">
          <ul>
            <span class="info">
              {{ devisObject.data?.devis?.client?.rue }}
            </span>
            <span class="info">
              {{ devisObject.data?.devis?.client?.cp }}
              {{ devisObject.data?.devis?.client?.ville }}
            </span>
            <li>
              <span class="entrepriseinfo">{{ $t("TEL") }} : </span>
              <span> {{ devisObject.data?.devis?.client?.tel }} </span>
            </li>
            <li>
              <span class="entrepriseinfo">{{ $t("ZONE") }} : </span>
              <span>{{ devisObject.data?.devis?.client?.zone }} </span>
            </li>
            <li>
              <span class="entrepriseinfo"
                >{{ $t("Type de chauffage") }} :
              </span>
              <span>
                {{ devisObject.data?.devis?.logement?.type_chauffage }}
              </span>
            </li>
            <li>
              <span class="entrepriseinfo"
                >{{ $t("Type de logement") }} :
              </span>
            </li>
            <li>
              <span> {{ devisObject.data?.devis?.logement?.type }} </span>
              <span>/+ {{ devisObject.data?.devis?.logement?.ancienter }} ans</span>
            </li>
            <li>
              <span>/{{ devisObject.data?.devis?.logement?.surface }} m²</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div class="d-flex">
        <div class="flex-grow-1">
          <b>{{ $t("DETAILS_DEVIS") }} </b>
        </div>
        <div class="flex-grow-1">
          <div class="d-flex justify-content-around">
            <div>
              <b>{{ $t("QTE") }} </b>
            </div>
            <div>
              <b>{{ $t("PUHT") }}</b>
            </div>
            <div>
              <b>{{ $t("TOTALHT") }}</b>
            </div>
            <div>
              <b>{{ $t("VAT") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div>
        BAR-TH-171 : Mis en page d'une pompe à chaleur de type air/eau,
        <b>{{
          devisObject.data.devis?.systeme_chauffage?.denomination_temperature
        }}</b
        >temperature.
      </div>
      <div>
        Le coefficient de performance (COP) est de :
        <b>{{
          devisObject.data?.devis?.systeme_chauffage?.coefficient_performance
        }}</b>
      </div>
      <div>
        L'efficacité énergitique saisonnière est de :
        <b>{{ devisObject.data?.devis?.systeme_chauffage?.efficacite }} %</b>
        calculée selon le règlement (EU)
      </div>
      <div>n°813/2013 de la commission du 2 aout 2013</div>
      <div>
        La surface chauffée par la PAC est de
        <b>{{ devisObject.data?.devis?.systeme_chauffage?.surface_chauffee }}</b>
      </div>
      <div
        v-for="(chantierItem, index) in devisObject.data
          .GroupeLigneDocument_produit"
        :key="index"
      >
        <div>
          <div v-if="chantierItem.type_ligne === 'produit'">
            <div>
              Marque :
            {{ chantierItem.fabricant}} 
            </div>
            <div>
              Réf :
{{ chantierItem.ref}}
        
            </div>
          </div>
        </div>
      </div>
      <div>
        Mise en place d'un régulateur de classe :
        <b>{{ devisObject.data?.devis?.systeme_chauffage?.regulateur_classe }}</b>
      </div>
      <div></div>
      <ul>
        <li>
          Dépose et remplacement d'une chaudière
          <b>{{devisObject?.data?.devis?.systeme_chauffage?.equipement_deposee}}</b>
        </li>
        <li>Kwh Cumac : <b>{{ devisObject.data?.devis?.cumac}}</b></li>
        <li>Prime Coup de pouce CEE : <b> BAR-TH-171 </b></li>
      </ul>
     <div v-if="devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null">
         Traveaux sous-traités auprès de l'entreprise 
        <b > {{ devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name }}</b>
      </div>
      <div v-else>
        Matériel(s) fourni(s) et mis en place par notre société
        <b > {{ devisObject.data.devis.entreprise?.lib }}</b>
      </div>
       <div v-if="devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null">
        représentée par
        {{ devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name}} , SIRET
        <b>
          {{ devisObject.data.groupeLigneDocument[0]?.sous_traitant?.num_siret }}
          ,</b>
        Certificat rge
      </div>
      <div v-else>
        représentée par
        {{ devisObject.data.devis.entreprise?.lib}} , SIRET
        <b>
          {{ devisObject.data.devis.entreprise?.siret }}
          ,</b>
        Certificat rge
      </div>
      <div>
        Numéro
        <b> {{ devisObject.data?.devis?.certificat_rge?.numero }} </b> attribué le
        <b>{{ devisObject.data?.devis?.debut_travaux }}</b> valable jusqu'au
        <b> {{ devisObject.data?.devis?.fin_travaux }}</b
        >, Assurance
      </div>
      <div>
        civile <b> N° {{ devisObject.data?.devis?.entreprise?.num_assurance }}</b>
      </div>
      <ul>
        <li>
          Date de la visite technique :<b>
            {{ devisObject.data?.devis?.visite_technique }}</b
          >
        </li>
      </ul>
      <div>
        <div
          v-for="(produit, idx) in devisObject.data.GroupeLigneDocument_produit"
          :key="idx"
          class="row mb-2"
        >
          <div class="col-md-6">
           <div><b>{{ produit.lib }}</b></div>
            <p>{{ produit.desc }}</p>
          </div>

          <div class="col-md-6">
            <div class="row text-center">
              <div class="col">
                <b>{{ produit.quantite }}</b>
              </div>
              <div class="col">
                <b>{{ produit.unit_price }}</b>
              </div>
              <div class="col">
                <b>{{ produit.type_unite }}</b>
              </div>
              <div class="col">
                <b>{{ produit.tva }}%</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="part">
        
        </div>
      </div>

      <div class="part">
        <div><b>Termes et conditions CEE</b></div>
        <p>
          Les travaux ou prestations objet du présent document donneront lieu à
          une contribution financiére de
          {{ devisObject.data.devis?.partenaire?.nom }} (SIRET
          {{ devisObject.data.devis?.partenaire?.siret }}), versée par
          {{ devisObject.data.devis?.partenaire?.nom }} dans le cadre de son role
          actif et incitatif sous forme, directement ou via son mondataire
          {{ devisObject.data.devis?.mandataire?.nom }} sous réserve de
          l'engagement de fournir excclusivement à
          {{ devisObject.data.devis?.partenaire?.nom }} les documents nécessaires
          à la valorisation des opérations au titre du dispositif des
          Certificats d'Economies d'Energie et sous réserve de la validation de
          l'éligibilité du dossier par
          {{ devisObject.data.devis?.partenaire?.nom }} puis par l'autorité
          administrative compétente. Le montant de cette contribution
          financiére, hors champ d'application de la TVA, est susceptible de
          varier en fonction des travaux effectivement réalisé et du volume des
          CEE attribués à l'opération et est estimé à 4 000,00 € (*)
        </p>
        <div></div>
        <p>
          Le client accepte que {{ onlineUser.entreprise.lib }} collecte et
          traite ses données à caractére personnel pour les besoins du dépot
          d'un dossier CEE sur le registre EMMY conformément aux dispositions
          réglementaires en vigueur et qu'il communique ces données à
          {{ devisObject.data.devis?.partenaire?.nom }} à des fins de controle de
          la conformité des operations réalisées chez le client
          {{ devisObject.data.devis?.partenaire?.nom }}
          s'engage à respecter la réglementation française et européenne
          relative à la protection des données à caractére personnel Le
          bénéficiaire renconnait avoir pris connaissance et accepter les
          conditions générales de vente figurant ci-jointes.
        </p>
        <div>
          <div class="cadre d-flex">
            <div class="">
              <div>
                Apposer signature précédée de la mention "Bon pour accord"
              </div>
              <div>Le :</div>
            </div>
            <div class="ml-auto p-2">
              <div class="row">
                <div class="col-6">Total H.T</div>
                <div class="col-6">299.6</div>
              </div>
              <div class="row">
                <div class="col-6">Total TVA</div>
                <div class="col-6">{{ devisObject.data?.total_TVA }}</div>
              </div>
              <div class="row">
                <div class="col-6"><b>Total TTC</b></div>
                <div class="col-6">
                  {{ devisObject.data.groupeLigneDocument[0]?.total_TTC }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">PrimeCEE</div>
                <div class="col-6">
                  {{ devisObject.data?.devis?.montant_cee }}
                </div>
              </div>
              <!-- <div class="red row">
                <div class="col-6">Reste à payer</div>
                <div class="col-6">{{ devisObject.data.groupeLigneDocument?.total_TTC }}</div>
              </div> -->
            </div>
             
          </div>

          <div class="d-flex justify-content-center">
            {{ onlineUser.entreprise?.lib }} -
            {{ devisObject.data.devis?.entreprise?.rue }} - au capital de
            {{ devisObject.data.devis?.entreprise?.cp }} Siret :{{
              devisObject.data.devis?.entreprise?.siret
            }}
            - N° {{ devisObject.data?.devis?.entreprise?.num_tva }}
          </div>
          <div class="d-flex justify-content-center">
            {{ devisObject.data.devis?.entreprise?.phone }} Courriel :
            {{ devisObject.data.devis?.entreprise?.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getOnlineUser']),
    onlineUser() {
      return this.getOnlineUser;
    },
  }
,
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  created() {

    if (this.devisObject.data && this.devisObject.data.devis) {
      this.formatDates();
    }
  },
  watch: {
    devisObject: {
      handler(newVal) {
     

        if (newVal.data && newVal.data.devis) {
          this.formatDates();
        }
      },
      deep: true,
    },
  },
  methods: {
   validateInputs() {
    return true
     
    },
    formatDates() {

      if (this.devisObject.data.devis.visite_technique) {
        this.devisObject.data.devis.visite_technique = new Date(this.devisObject.data.devis.visite_technique).toISOString().slice(0, 10);
      }
      if (this.devisObject.data.devis.debut_devis) {
        this.devisObject.data.devis.debut_devis = new Date(this.devisObject.data.devis.debut_devis).toISOString().slice(0, 10);
      }
      if (this.devisObject.data.devis.fin_devis) {
        this.devisObject.data.devis.fin_devis = new Date(this.devisObject.data.devis.fin_devis).toISOString().slice(0, 10);
      }
    },
    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
  },
}
</script>
<style lang="scss" scoped>
div {
  font-size: 13px;
}
.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.brouillon{
border-radius:25px;
}
b{
  color: #28367a;
}
.red{
  font-weight: bold;
  color: #e4261a;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }

  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
    height: 250px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .half-width {
  flex: 0 0 25%; 
}
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}

.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

<template>
  <div>
    <div class="d-flex justify-content-end mt-4 px-0">
      <b-dropdown
        text="Ajouter"
        class="button-pre-style mb-2"
        variant="primary"
        right
      >
        <b-dropdown-item @click="addRow('Product')">Product</b-dropdown-item>
        <b-dropdown-item @click="addRow('Forfait')">Forfait</b-dropdown-item>
        <b-dropdown-item @click="addRow('Main d\'oeuvre')"
          >Main d'oeuvre</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <div class="d-flex justify-content-center">
      <div class="border">
        <div class="scroll-container">
          <div class="content-wrapper">
            <div class="header d-flex mb-3 px-3 pt-2">
              <div class="header-item" style="flex: 1.5">
                {{ $t("Produit") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("REF") }}
              </div>
              <div class="header-item" style="flex: 3">
                {{ $t("WORDING") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("QTE") }}
              </div>
              <div class="header-item" style="flex: 1.5">
                {{ $t("type_uniteU") }}
              </div>
              <div class="header-item" style="flex: 1.5">
                {{ $t("PUHT") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("TOTALHT") }}
              </div>
              <div
                class="header-item"
                style="flex: 1; border-radius: 0 5px 0 0"
              >
                {{ $t("VAT") }}
              </div>
            </div>

            <div
              v-if="devisObject.data.GroupeLigneDocument_produit.length === 0"
              class="text-center my-4"
            >
              <p>Aucune donnée</p>
            </div>
            <div v-else>
              <div
                v-for="(row, index) in devisObject.data
                  .GroupeLigneDocument_produit"
                :key="index"
                class="row-item d-flex"
              >
                <form @submit.prevent="handleSubmit">
                  <div class="row m-2">
                    <div class="input-wrapper mx-2" style="flex: 2">
                      <searchInput
                        v-if="row.type === 'Product'"
                        :list="getAllProducts"
                        :loader="getProductLoading"
                        label="lib"
                        placeholder="Produit"
                        :searchFunc="all_products"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                        @change="limitDisplay"
                        :formatter="formatSelectedItems"
                        class="truncated-label"
                      ></searchInput>
                      <searchInput
                        v-if="row.type === 'Forfait'"
                        :list="getAllforfaits"
                        :loader="getforfaitLoading"
                        label="lib"
                        placeholder="Forfait"
                        :searchFunc="all_forfaits"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                      ></searchInput>
                      <searchInput
                        v-if="row.type === 'Main d\'oeuvre'"
                        :list="getAllmainoeuvres"
                        :loader="getmainoeuvreLoading"
                        label="lib"
                        placeholder="Main d'oeu.."
                        :searchFunc="all_mainoeuvres"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                      ></searchInput>
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                  
                      <b-form-input
                        v-model="row.ref"
                        maxlength="10"
                        class="custom-input"
                      />
                    </div>
                    <div class="input-wrapper ms-2" style="flex: 3">
                      <b-form-input
                        class="custom-input"
                        v-model="row.lib"
                        @input="row.lib = $event.toUpperCase()"
                        required
                        maxlength="100"
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                      <b-form-input
                        class="custom-input"
                        type="number"
                        v-model.number="row.quantite"
                        min="1"
                        required
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-select
                        class="custom-input"
                        v-model="row.type_unite"
                        :options="optionstype"
                        text-field="text"
                        value-field="value"
                        required
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-input
                        class="custom-input"
                        type="number"
                        v-model.number="row.unit_price"
                        required
                        step="1"
                        min="0"
                        max="10000000.00"
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-input
                        class="custom-input"
                        :value="totalHT(row)"
                        readonly
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                      <b-form-select
                        class="custom-input"
                        v-model.number="row.tva"
                        :options="optionsTVA"
                        required
                      />
                    </div>
                  </div>

                  <div class="row input-wrapper m-2">
                    <div style="flex: 0.5"></div>
                    <div class="mx-2" style="flex: 0.5">Description</div>
                  </div>
                  <div class="row input-wrapper m-2">
                    <div style="flex: 2"></div>
                    <div style="flex: 12">
                      <b-form-textarea
                        class="custom-input"
                        v-model="row.desc"
                        placeholder="Ajouter description"
                        rows="5"
                      ></b-form-textarea>
                    </div>
                  </div>
                  <div class="row m-2">
                    <div style="flex: 13"></div>
                    <div style="flex: 1">
                      <b-button
                        @click="removeRow(index)"
                        class="button-pre-style custom-input"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </div>
                  </div>

                  <hr
                    v-if="
                      index <
                      devisObject.data.GroupeLigneDocument_produit.length - 1
                    "
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchInput from "@/components/ui/searchInput";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  components: {
    searchInput,
  },

  data() {
    return {
      selected:false,
      deletedIds: [],
      form: {},
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getProductLoading",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getAllentreprises",
      "getOnlineUser",
      "getforfaitLoading",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
  methods: {
    ...mapActions([
        "store_mainoeuvre",
      "all_products",
      "all_mainoeuvres",
      "all_forfaits",
      "store_forfait",
      "store_product",
      "store_mainoeuvre",
    ]),
    limitDisplay() {
      this.row.selectedItems = [...this.row.selectedItems];
    },
    addRow(type) {
      this.devisObject.data?.GroupeLigneDocument_produit.push({
        groupelignedocument_id: this.devisObject.data.groupeLigneDocument[0].id,
        type: type,
        ref: "",
        lib: "",
        quantite: 1,
        type_unite: null,
        unit_price: 0,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });
    },
   
   
   
    formatSelectedItems(selectedItems) {
      let displayText = selectedItems.join(", ");
      if (displayText.length > 100) {
        displayText = displayText.substring(0, 10) + "...";
      }
      return displayText;
    },

    removeRow(index) {
      const rowToRemove =
        this.devisObject.data?.GroupeLigneDocument_produit[index];

      const idToRemove = rowToRemove.id;

      if (idToRemove !== null) {
        this.deletedIds.push(idToRemove);

        this.devisObject.data?.GroupeLigneDocument_produit.splice(index, 1);
      } 

      this.$emit("deletedIds", this.deletedIds);
      this.emitUpdate();
    },
    addProduit(selectedItem, index) {
      if (
        this.devisObject.data.GroupeLigneDocument_produit[index].type ===
        "Product"
      ) {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        this.$set(this.devisObject.data.GroupeLigneDocument_produit, index, {
          ...this.devisObject.data.GroupeLigneDocument_produit[index],
          ref: product.ref,
          lib: product.lib,
          type_ligne: product.type_ligne,
          unit_price:
            this.devisObject.data.groupeLigneDocument[0].unit_price ||
            product.unit_price,
          type_unite: product.type_unite,
          tva: product.tva,
          desc: product.desc,
          fabricant: product.fabricant,
          searchInputValue: product,
          totalHT: product.unit_price * product.quantite,
        });
        this.selected=true
      } else if (
        this.devisObject.data.GroupeLigneDocument_produit[index].type ===
        "Forfait"
      ) {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );
    
        this.$set(this.devisObject.data.GroupeLigneDocument_produit, index, {
          ...this.devisObject.data.GroupeLigneDocument_produit[index],
          ref: forfait.ref,
          lib: forfait.lib,
          unit_price: forfait.unit_price,
          type_unite: forfait.type_unite,
          tva: forfait.tva,
          type_ligne: forfait.type_ligne,
          desc: forfait.desc,
          searchInputValue: forfait,
          totalHT: forfait.unit_price * forfait.quantite,
        });
        this.selected=true
      } else if (
        this.devisObject.data.GroupeLigneDocument_produit[index].type ===
        "Main d'oeuvre"
      ) {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        this.$set(this.devisObject.data.GroupeLigneDocument_produit, index, {
          ...this.devisObject.data.GroupeLigneDocument_produit[index],
          ref: mainoeuvre.ref,
          lib: mainoeuvre.lib,
          desc: mainoeuvre.desc,
          unit_price: mainoeuvre.unit_price,
          type_ligne: mainoeuvre.type_ligne,
          type_unite: mainoeuvre.type_unite,
          tva: mainoeuvre.tva,
          searchInputValue: mainoeuvre,
          totalHT: mainoeuvre.unit_price * mainoeuvre.quantite,
        });
        this.selected=true
      }

    },
    
    
    
    
    totalHT(row) {
      if (row.unit_price && row.quantite) {
        return (row.unit_price * row.quantite).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style>
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}
.border {
  border: 1px solid #24316d !important;
}
.custom-input {
  box-shadow: none !important;
  width: 100%;
  height: 38px;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.border {
  display: block;
  width: 100%;
  border: 1px solid #24316d;
  margin-top: 20px;
  overflow-x: auto;
  box-sizing: border-box;
}

.scroll-container {
  overflow-x: auto;
}

.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
</style>

<template>
  <div class="">
    <div class="row mt-4">
      <!-- First Column -->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <form>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Règlement</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <div class="">
              <div class="fieldset mt-3">
         
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>
                <b-form-select
                  v-model="devisObject.data.devis.moyens_paiement"
                  :options="optionspaiement"
                  text-field="text"
                  value-field="value"
                  required
                  class="form-input custom-placeholder custom-input"
                ></b-form-select>
              </div>
            </div>

            <b-form-group label="Debut travaux">
              <b-form-input
              type="date"
              v-model="devisObject.data.devis.debut_travaux"
              required
              class="form-input custom-placeholder custom-input"
              @input="emitUpdate"
            ></b-form-input>
             
            </b-form-group>

            <b-form-group label="Fin travaux">
              <b-form-input
                type="date"
              
                v-model="devisObject.data.devis.fin_travaux"
                required
                  @input="emitUpdate"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>

      <!-- Second Column -->
      <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">Mandataire</div>
          </div>
        </div>
        <form>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group label="Mandataire">
               <multiselect
              v-model="selectedMondataire"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getMondataireEntitesExternes"
              label="nom"
              track-by="id"
              :placeholder="$t('Select_agent')"
              class="form-input custom-placeholder custom-input"
            /> 
            </b-form-group>
          </div>

          <div class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">Partenaire</div>
              </div>
            </div>

            <b-form-group label="Partenaire">
              <multiselect
              v-model="selectedPartenaire"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getPartenaireEntitesExternes"
              label="nom"
              track-by="id"
              :placeholder="$t('Select_agent')"
              class="form-input custom-placeholder custom-input"
            /> 
            
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {


  data() {
    return {
      moyens_paiement: "financement",
      partenaire: this.devisObject.data.devis.partenaire,
      mondataire: this.devisObject.data.devis.mandataire || null,
      messages: "Veuillez remplir tout les champs correctement !",
      showErrorAlert: false,
      showSuccessAlert: false,
      optionspaiement: [
        { value: "financement", text: this.$t("FINANCEMENT") },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
      ],
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getAllEntitesExternes",
      "getOnlineUser",
      "getMondataireEntitesExternes",
      "getPartenaireEntitesExternes",
      "getLoadingEntiteExterne",
    ]),
    selectedPartenaire: {
      get() {
        if (typeof this.partenaire === 'object' && this.partenaire !== null) {
          return this.partenaire;
        } else {
          return this.getPartenaireEntitesExternes.find(
            option => option.id === this.partenaire
          );
        }
      },
      set(value) {
       
        this.partenaire = value ? value.id : null;
        this.devisObject.data.devis.partenaire_id = value.id;
        this.emitUpdate()
      },
    },
    selectedMondataire: {
      get() {
        if (typeof this.mondataire === 'object' && this.mondataire !== null) {
          return this.mondataire;
        } else {
          return this.getMondataireEntitesExternes.find(
            option => option.id === this.mondataire
          );
        }
      },
      set(value) {
       
        this.mondataire = value ? value.id : null;
        this.devisObject.data.devis.mandataire_id = value.id;
        this.emitUpdate()
      },
    },
  },
  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.allEntitesExternes({
      page: 1,
      per_page: 10,
      search: "",
      type: "",
    });

    if (this.devisObject.data && this.devisObject.data.devis) {
      this.initializeDateData(); 
      this.formatDates(); 
    }
  },
  methods: {
    ...mapActions(["allEntitesExternes"]),
    initializeDateData() {
      const devis = this.devisObject.data.devis;

      if (!devis.debut_travaux) {
        devis.debut_travaux = ""
      }
      if (!devis.fin_travaux) {
    
        devis.fin_travaux = ""
      }
    },
    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
    async fetchData() {
      await this.allEntitesExternes({
        page: 1,
        per_page: 10,
        search: "",
        type: "",
      });
    },
    selectMondataire(mondataire) {
      this.mondataire = { ...mondataire };

      this.devisObject.data.devis.mandataire_id = mondataire.id;

      this.emitUpdate();
    },
    
    
    selectPartenaire(partenaire) {
      this.partenaire = { ...partenaire };
      this.devisObject.data.devis.partenaire_id = partenaire.id;

      this.emitUpdate();
    },
       formatDates() {
      const addDay = 24 * 60 * 60 * 1000;


      if (this.devisObject.data.devis.debut_travaux) {
        const debutDate = new Date(this.devisObject.data.devis.debut_travaux);
        this.devisObject.data.devis.debut_travaux = new Date(
          debutDate.getTime() + debutDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }

      if (this.devisObject.data.devis.fin_travaux) {
        const finDate = new Date(this.devisObject.data.devis.fin_travaux);
        this.devisObject.data.devis.fin_travaux = new Date(
          finDate.getTime() + finDate.getTimezoneOffset() * 60000 + addDay
        )
          .toISOString()
          .slice(0, 10);
      }
    },
 
    created() {
      this.selectPartenaire(this.devisObject.data.partenaire)
      this.selectMondataire( this.devisObject.data.mandataire)
    }
  },
};
</script>
<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.custom-input {
  box-shadow: none;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
</style>
